import type { TalkData } from "content-ui/talks/types";
import type { SubscriptionLevel } from "lib/types";
import Button from "components/buttonNew/button";

type RsvpButtonProps = {
  talk: TalkData;
  onClickRegister: any;
  rsvpSent: boolean;
  userLevel: SubscriptionLevel | undefined;
};

export const RsvpButton = ({
  talk,
  onClickRegister,
  rsvpSent,
  userLevel,
}: RsvpButtonProps) => {
  // Talk Booleans (set in Dato)
  const isOversubscribed = talk.isOversubscribed || false;
  const isMemberOnly = talk.isMemberOnly || false;

  // User Level
  const notLoggedIn = userLevel === "unregistered";
  const isHigherTierMember =
    userLevel === "pro" || userLevel === "member" || userLevel === "startup";

  const renderButtonCopy = () => {
    if (isOversubscribed) {
      return "Event oversubscribed";
    }
    if (isMemberOnly) {
      if (isHigherTierMember) {
        return "RSVP";
      } else {
        return "Join to RSVP";
      }
    } else {
      if (notLoggedIn) {
        return "Register to RSVP";
      } else {
        return "RSVP";
      }
    }
  };

  return (
    <Button
      data-testid="talks-registration-cta" // TODO: This will not render (maybe modify base component to accept testid prop string)
      size={"medium"}
      className="w-full min-w-[220px] md:w-auto"
      onClick={onClickRegister}
      disabled={isOversubscribed || rsvpSent}
    >
      {renderButtonCopy()}
    </Button>
  );
};
